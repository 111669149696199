.chatPage {
  width: 90%;
  margin: auto;
  .chatInput {
    .MuiTextarea-root {
      border: 1px solid #f0f2f5 !important;
      box-shadow: 0px 2px 4px 0px #19213d14 !important;
      min-height: 172px;
      border-radius: 16px;
      padding: 1.5rem !important;
    }
    .MuiTextarea-textarea {
      max-height: 64px !important;
      overflow: auto !important;
      &::placeholder {
        color: #666f8d !important;
      }
    }
    .send {
      width: 15px;
      height: 15px;
      /*rotate: -45deg;*/
    }
  }
  .chat-container {
    display: flex;
    flex-direction: column;
    gap: 1.7rem;
    .chatResponse {
      h6 {
        color: #19213d !important;
        font-family: var(--inter-font);
        font-weight: 500;
      }
      > p {
        color: #343434 !important;
        font-family: var(--inter-font);
        font-weight: 500;
        background-color: var(--color-light);
        padding: 24px;
        border-radius: 15px;
      }
    }
    .chatAnswer {
      > div {
        box-shadow: 0px 2px 4px 0px #19213d14 !important;
        border: 1px solid #f0f2f5;
        border-radius: 16px;
        padding: 1.5rem !important;
        background-color: #f7f8fa;
      }

      .messageHeader {
        display: flex;
        gap: 2rem;
        align-items: center;

        h6 {
          position: relative;

          > span {
            //color: #19213d !important;
            font-family: var(--inter-font);
            font-weight: 500;
            margin-top: 3px;
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: -1rem;
            width: 1px;
            height: 100%;
            background-color: #f0f2f5;
          }
        }
        > span {
          color: #666f8d !important;
          font-family: var(--inter-font);
          font-weight: 500;
        }
      }
      .messageContent {
        p {
          color: #343434 !important;
          font-family: var(--inter-font);
          font-weight: 400;
          margin-bottom: 1rem;
          margin-block-start: 0;

        }
      }
    }
  }
  .files-upload {
    gap: 1rem;
    .chatCategory {
      padding: 8px 12px !important;
      background-color: var(--sub-main);

      border: 1px solid var(--border-color);
      box-shadow: 0px 1px 3px 0px #19213d1a;
      button {
        color: var(--invert-color) !important;
        font-family: var(--inter-font) !important;
        font-weight: 500;
      }
    }
    .css-b0m4zx-JoySelect-indicator {
      display: none !important;
    }
  }
}

.chatHistory {
  height: 100vh;
  position: relative;
  .MuiPaper-root {
    background-color: var(--side-color) !important;
    width: 100% !important;
    color: var(--invert-color) !important;
    border: none !important;
    // background-color: transparent !important;
    box-shadow: none !important;
    padding: 1rem;
    max-width: 296px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .delete-button * {
      color: red !important;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    h3 {
      margin: 0;
      font-family: var(--inter-font);
      font-weight: 500;
      color: #bac0cc;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 0 !important;
    height: calc(100vh - 300px) !important;

    li {
      > div {
        padding: 6px 10px !important;
        transition: 0.4s !important;
        &:hover {
          background: var(--primary) !important;
          * {
            color: #fff !important;
          }
        }
        > div {
          margin: 0 !important;
          span {
            color: #666f8d !important;
            font-weight: 400;
            font-family: var(--inter-font);
            font-size: 14px !important;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

// Tablet and smaller
@media only screen and (max-width: 1024px) {
  .layout-main:has(> .chatPage) {
    margin-right: -295px;
  }
  .chatPage {
    width: 100%;
    .files-upload {
      flex-wrap: wrap;
      > div {
        flex-wrap: wrap;
        gap: 0.5rem !important;
      }
      + .btn__primary {
        span {
          display: none;
        }
      }
    }
  }
}
