// Mobile Screen

@media screen and (max-width: 576px) {
  .profile {
    .MuiFormControl-root {
      margin-bottom: 0;
    }
    .MuiBox-root {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-top: 1rem;
      gap: 1rem;
      > * {
        width: 100%;
      }
      > div {
        padding: 0;
      }
      .MuiFormControlLabel-root {
        margin: 0 !important;
      }
      .MuiButtonBase-root {
        margin: 0;
      }
    }
    &__header {
      padding-inline: 0;
      margin-top: 70px !important;
    }
    &__name {
      font-size: 1.4rem !important;
      font-weight: bold !important;
    }
    &__email {
      font-size: 1rem !important;
    }
    &__tabs {
      width: fit-content;
      .MuiTabs-flexContainer {
        flex-wrap: wrap;
        button {
          flex: 1;
        }
      }
    }
    &__btn {
      margin-top: 1rem !important;
      width: 100%;
    }
    &__tab {
      > div {
        padding: 0;
      }
    }
  }
}
