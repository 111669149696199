.modal {
  .centered-modal {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 576px) {
  .modal {
    .centered-modal {
      width: 90% !important;
    }
  }
}
