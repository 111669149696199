/*Global style*/
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Noto+Sans+New+Tai+Lue:wght@400..700&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: 'GaddCustomFont';
  src: url('../../fonts/efrra/Effra_Trial_SBd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

*{
  font-family: "GaddCustomFont",sans-serif !important;
}

html,
.dvGIvv,
body {
  background: #fff  !important;
}
a {
  text-decoration: none !important;
}

#Snackbar-status {
  background: var(--color-primary);
}

.css-141e2t4-JoyTextarea-root:not([data-inverted-colors="false"]) {
  --Textarea-focusedHighlight: transparent !important;
}

.css-bqm782:not([data-inverted-colors="false"]) {
  --Textarea-focusedHighlight: transparent !important;
}

.css-bqm782 {
  --Textarea-focusedHighlight: transparent !important;
}

.css-1wcrf7k {
  flex-flow: wrap;
}

.css-1tixzle {
  margin-right: 0 !important;
  /*color: #c0e8f8;*/

}

div span.sub-titles-color {
  color: var(--color-primary) !important;
}

.css-141e2t4-JoyTextarea-root::before {
  box-shadow: none !important;
}

.MuiTextarea-root.Mui-focused {
  color: #939393 !important;
}

.MuiTextarea-root:hover {
  color: #939393 !important;
}

.resources-card .css-rsp7ti:last-child {
  padding-bottom: 0 !important;
}

.resources-card .css-rsp7ti {
  padding: 0 !important;
}

.circle {
  fill: var(--color-primary)!important;
}

.button-subscribe.MuiPaper-root {
  color: #fff !important;
}

.subscribe-input .MuiOutlinedInput-input {
  background-color: rgba(10, 67, 61, 0.16) !important;
}

.resources-card .css-cg0ozc-MuiCardContent-root:last-child {
  padding-bottom: 5px !important;
}

.resources-card .css-cg0ozc-MuiCardContent-root {
  padding: 5px !important;
}

.css-1o1b2g4-JoyTextarea-root:not([data-inverted-colors='false']) {
  --Textarea-focusedHighlight: transparent !important;
}

.css-1swlzci:not([data-inverted-colors='false']) {
  --Textarea-focusedHighlight: transparent !important;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 110000 !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.block-search-result {
  display: flex;
  gap: 20px;
}

.word-animate {
  animation: fadeIn 0.5s ease-in;
}

.dark-bg {
  stroke: #ffff !important;
}

.loader {
  position: relative;
  margin: 15px 35px;
}

.dot {
  position: absolute;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: var(--color-primary) ;
  animation: bounce 1.3s linear infinite;
}

.dot:nth-child(1) {
  left: -1.5rem;
}

.dot:nth-child(2) {
  animation-delay: -1s;
}

.dot:nth-child(3) {
  right: -2.5rem;
  animation-delay: -0.8s;
}

@keyframes bounce {

  0%,
  66%,
  100% {
    transform: initial;
  }

  33% {
    transform: translatey(-1rem);
  }
}

::-webkit-scrollbar {
  width: 5px;
  /* Width of the entire scrollbar */
  height: 5px;
  /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent;

}

::-webkit-scrollbar-thumb {
  background: #888;

  border-radius: 6px;

}

::-webkit-scrollbar-thumb:hover {
  background: #555;

}



.bTsUih,
.gmVMps {
  background-color: transparent !important;
}


p {
  color: #939a99;
}

h3 {
  color: #a1a1a1;
  font-size: 16px;
}

.d-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}
.justify-content-start {
  justify-content: flex-start !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.pt-2 {
  padding-top: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-0 {
  margin-bottom: 0;
}

.mr-1 {
  margin-right: 4px;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 16px;
}

.pb-1 {
  padding-bottom: 8px;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flow {
  display: flow;
}

.color-main {
  color: var(--color-primary) !important;
}
.title-chip{
  background-color: #f0f5f5;
  color: #5c6587;
  text-align: center !important;
  border-radius: 4px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
}
.cursor-pointer {
  cursor: pointer;
}

.theme-btn,
.lang-btn {
  position: fixed;
  left: 32px;
  z-index: 100;
  bottom: 99px;
}

.lang-btn {
  bottom: 135px;
}

.theme-btn svg,
.lang-btn svg {
  width: 20px;
}

.theme-btn.login {
  left: auto;
  bottom: auto;
  top: 30px;
  right: 30px;
  display: none;
}

.theme-btn.dark {
  color: #fff;
}

.theme-btn button,
.lang-btn button {
  width: 20px;
  color: #939a99 !important;
}

.lang-btn.login {
  display: none;
}

.modal-modal-description {
  line-height: 28px !important;
  max-height: calc(100vh - 276px);
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  text-align: justify;
}

.custom-width {
  /*left: calc(10% + 30px) !important;*/
  /*width: calc(80% - 60px) !important;*/
}

.small-width {
  max-width: 45%;
  margin-left: 25% !important;
  margin-top: 40px !important;
}

.w-100 {
  width: 100% !important;
}

#app>div>div:nth-child(2) {
  width: 100% !important;
  height: 100%;
}

h4 {
  font-size: 15px !important;
  color: #666666 !important;
}

h4 p {
  font-size: 14px !important;
}

.modal-modal-description ol {
  margin-top: 4px !important;
  margin-bottom: 10px !important;
}

.css-1j39oz4 {
  margin-bottom: 0 !important;
}

.card {
  border-radius: 12px !important;
  background: var(--color-primary) ;
  padding: 20px;
}

.card-title {
  margin-bottom: 50px;
  font-size: 18px;
  color: #d9d9d9;
}

h6 {
  color: #a8a8a8;
}

/*Search Style*/
.isAquF {
  display: block !important;
  width: auto !important;
}

.flex-gap-regular {
  gap: 24px;
}

.gap-10 {
  gap: 10px;
}

.search-top {
  z-index: 1000;
  width: 100% !important;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: end;
}

.search-top.dark {
  background-color: var(--color-primary) ;
}

.ar {
  left: 0 !important;
}

.en {
  right: 0 !important;
}

.profile-icon {
  color: #939a99;
  cursor: pointer;
  margin-bottom: 4px;
  margin-left: 4px;
}

.profile-icon:hover::before {
  display: none !important;
}

.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  background: #152e2b;
}

.show-pass-profile {
  color: #4a5b59 !important;
}

.search-top.search {
  position: fixed;
  width: calc(100% - 99px);
  z-index: 1;
  /*width: 100%;*/
  background: var(--color-primary) ;
  top: 10px;
  border-radius: 8px 8px 0 0;
}

.search-top.search:before {
  content: '';
  position: fixed;
  top: 0;
  height: 10px;
  left: 0;
  width: 100%;
  background-color: var(--color-primary) ;
}

#content.search {

  background-color: var(--color-primary) ;
}

.search-top.drawer-open {
  width: calc(100% - 290px);
}

.MuiButton-containedPrimary {
  text-transform: capitalize !important;
  border-radius: 8px !important;
  background: var(--color-primary) !important;
  box-shadow: 0px 2px 5px 0px rgba(20, 88, 201, 0.17), 0px -2px 0.3px 0px rgba(14, 56, 125, 0.18) inset,
  0px 2px 1px 0px rgba(255, 255, 255, 0.22) inset !important;
}

#content {
  padding: 0 20px;
  margin-top: 55px;
}

.css-xy52n1 {
  margin-left: 0 !important;
}

.related-searches p {
  cursor: pointer;
  padding: 0 16px;
  margin-right: 12px;
  border-radius: 7px;
  background: var(--color-primary) ;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 32px;
}

.font-weight-bold {
  font-weight: 700;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.1) !important;
}


.related-searches p:hover {
  background-color: var(--color-primary) ;
}

.search-top-content {
  width: 75% !important;
}

#text-color {
  color: #f1f1f1 !important;
  margin-inline: 5px;
}

#search-word {
  margin-inline: 20px !important;
  color: #232323 !important;
}

.custom-placeholder .MuiInputBase-input::placeholder {
  color: #ff0000 !important;
  opacity: 1 !important;
}

.input-search.MuiPaper-root {
  font-family: IBM Plex Sans, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  height: 55px;
  width: 90% !important;
  padding: 12px !important;
  color: #939a99 !important;
  margin-bottom: 25px;
  border-radius: 10px !important;
  border: 1px solid rgba(51, 61, 60, 0.34) !important;
  background-color: var(--color-primary)  !important;
}

@media (max-width: 991px) {
  .input-search.MuiPaper-root {
    margin-left: 0;
  }
}

.MuiAutocomplete-root.Mui-focused {
  outline: none !important;
  border: none !important;
}

.input-search.MuiPaper-root .buttonSearch {
  padding: 0 !important;
  margin-right: 4px;
}

.buttonSearch svg {
  width: 15px;
  height: 15px;
}

.input-search:focus {
  border-color: #303740;
  box-shadow: 0 0 0 3px #303740;
}

.MuiInputBase-formControl,
.MuiFormControl-root {}

#inputSearch {
  border: 0 !important;
  color: #939a99 !important;
  font-size: 12px;
  font-weight: 400;
}

.css-p1olib-MuiAutocomplete-endAdornment {
  display: none;
}

.search-container .MuiAutocomplete-endAdornment,
.search-container .MuiInputBase-root.MuiInput-underline:before,
.search-container .MuiInputBase-root.MuiInput-underline:after {
  display: none !important;
}

.search-container .MuiAutocomplete-popper {
  width: 100%;
}

.input-search button,
.input-search input::placeholder,
.input-search.MuiPaper-root #inputSearch::placeholder,
.chat-input textarea::placeholder {
  color: #939a99 !important;
}

.input-search .search-filter.MuiButtonBase-root {
  padding: 0 !important;

}

.css-953pxc-MuiInputBase-root-MuiInput-root::before {
  border-bottom: none !important;
}

.input-search .search-filter.MuiButtonBase-root svg {
  width: 19px;
}

.MuiPaper-root {
  color: #ffffff;
  border-radius: 4px !important;
  background: var(--color-primary)  !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border: 0 !important;
}

body .MuiAutocomplete-popper.MuiPaper-root,
.css-176lby7-MuiPopper-root-MuiAutocomplete-popper {
  width: 305px !important;
  margin: 0px !important;
  transform: translate3d(188px, 66.4px, 0px) !important;
}

.MuiAutocomplete-popper.MuiPaper-root * {
  color: #fff !important;
  font-size: 12px;
  border-bottom: 1px solid #7c7c7c61;
}

.MuiPopover-root .MuiPaper-root.MuiPaper-elevation {
  margin-bottom: 20px;
  box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 5%) !important;
}

.chat-popover .MuiPaper-root.MuiPaper-elevation {
  transform: translate(-30px, -77px) !important;
}

.search-popover .MuiPaper-root.MuiPaper-elevation {
  transform: translate(-157px, 10px) !important;
}

.MuiAutocomplete-option {
  /*color: #fff !important;*/
  border-bottom: 1px solid #7c7c7c61;
  font-size: 12px;
}

.MuiPaper-root nav {
  padding-top: 0;
  padding-bottom: 0;
  min-width: 180px;
  width: 180px;
  color: #ffffff;
  border-radius: 4px;
  background: var(--color-primary) ;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25) !important;
  border: 0 !important;
}


.MuiPaper-root span,
.MuiListItemText-root span,
.MuiListItemText-root {
  font-size: 13px !important;
  display: block;
}

.MuiPaper-root span {
  display: inline-block;
}

.MuiPaper-root .Mui-selected span {
  color: #fff !important;
}

#app {
  overflow-x: hidden;
  /*!*background-color: var(--color-primary) ;*!*/
  /*background-color: var(--color-primary) ;*/
}

#app>div:first-of-type {
  max-width: 100% !important;
  display: block;
  min-height: calc(100vh - 20px) !important;
  height: auto;
  /*background-color: var(--color-primary) ;*/
  border-radius: 12px;
}

.light-theme {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.search-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: calc(100% - 80px);
  min-height: calc(100vh - 20px);
  margin-left: 80px;
  margin-top: 60px;
}

.results-content {
  overflow-y: auto;
  padding-right: 8px;
}

.title-taps {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 32px;
  border-bottom: 1px solid #333b3b;
  margin-top: 20px;
}

.MuiAutocomplete-inputRoot {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.custom-form .MuiSelect-select.MuiSelect-outlined {
  padding-top: 9px !important;
  padding-bottom: 10px !important;
}

.modal-modal-description::-webkit-scrollbar,
.scroll::-webkit-scrollbar,
.MuiAutocomplete-listboxl::-webkit-scrollbar {
  width: 8px !important;
}

.modal-modal-description::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track,
.MuiAutocomplete-listboxl::-webkit-scrollbar-track {
  background: #c1c1c110 !important;
}

.modal-modal-description::-webkit-scrollbar-thumb,
.MuiAutocomplete-listbox::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb {
  background: var(--color-primary) !important;
  border-radius: 8px;
}

.modal-modal-description::-webkit-scrollbar-thumb:hover,
.MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover,
.scroll::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary) ;
}

.result-container {
  display: flex;
  gap: 10px;
}

@media (max-width: 991px) {
  .result-container {
    flex-direction: column-reverse;
  }
}

.fixed-result-block {
  height: 80vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.result-container .data-block {
  border-radius: 8px;
  border: 1px solid #333b3b;
  padding: 15px 15px 0 15px;
  color: #fff;
  word-break: break-all;
  margin-bottom: 16px;
  background-color: var(--color-primary) ;
  transition: all 0.3s ease-in-out;
}
.result-container .data-block:hover {
  box-shadow: 0px 5px 5px 2px #0000001A;
}

.MuiPagination-root {
  display: flex !important;
  justify-content: center !important;
}

.MuiPaginationItem-root,
.MuiPagination-root button {
  color: #b7b7b7 !important;
}

.MuiPagination-root .Mui-selected {
  background-color: #fff !important;
  color: #182230 !important;
  border-radius: 8px !important;
}

.css-1ursewb-MuiPaper-root-MuiPopover-paper .Mui-selected {
  background-color: var(--color-primary) !important;
}

.css-1e3jsob-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
  background:var(--color-primary);
}

.result-container .data-block p {
  text-align: justify;
  margin-bottom: 12px;
  color: #939a99;
  font-size: 14px;
  border-bottom: 1px solid #323b3a;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.result-container .data-block h3 {
  font-size: 14px;
  font-weight: 500;
  color: #bec5c4;
  align-items: center;
}

.result-container .data-block h3 small {
  color: #ebeef3;
  font-size: 12px;
  margin-left: 4px;
  padding-top: 2px;
}
.MuiModal-root{
  display: flex;
}
.MuiModal-root  > .MuiBox-root {
  color: #ffffff;
  padding: 24px 36px;
  border-radius: 12px;
  background: var(--color-primary) ;
  border: 1px solid var(--color-primary) !important;
  box-shadow: none !important;
  margin: auto;
  min-width: 50%;
  width: 700px;
  max-width: 80%;
}
.MuiDialog-container{
  width: 100%;
}
.MuiModal-root .MuiBox-root.modal-large{
  width: 80% !important;
}
.MuiModal-root .MuiBox-root:focus {
  border: 1px solid #919c9b !important;
  box-shadow: none !important;
  outline: none !important;
}

.MuiModal-root .MuiBox-root h2 {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 14px;
  margin-bottom: 14px;
  border-bottom: 1px solid #cecece;
}

.MuiModal-root .MuiBox-root h2 button {
  border-radius: 50%;
  border: 2px solid #fff;
  padding: 1px;
}

.css-1r2bn3-JoySvgIcon-root {
  color: #fff !important;
}

.MuiModal-root .MuiBox-root h2 button svg {
  width: 14px;
  height: 14px;
}

.css-svm5m3-MuiPaper-root-MuiCard-root {
  padding: 0 !important;
}

.MuiOutlinedInput-input {
  border-radius: 4px !important;
  color: #eee !important;
  padding-left: 10px !important;
}

.MuiOutlinedInput-input:focus {
  box-shadow: none !important;
}

.result-container .data-block:last-child {
  border-bottom: 0;
}

.MuiChip-root {
  background: #2f2f2f !important;
  color: #7e7e7e !important;
}

.sentiment-meta {
  color: #80808080;
  min-width: 180px;
}
.ner-meta{
  flex-wrap: wrap;
  justify-content: flex-end;
}
.ner-meta > div{
  margin-left: 4px;
  margin-bottom: 4px;
}
.sentiment-meta button {
  color: #939a99 !important;
  font-size: 12px;
  padding: 4px 6px;
}

.sentiment-meta .MuiButton-textPrimary,
.MuiChip-filled {
  color: #939a99 !important;
  background: transparent !important;
  border: 1px solid #333 !important;
  cursor: auto;
}

.all-results {
  color: #fff !important;
  font-size: 14px !important;
}

.all-results p {
  margin-left: 4px;
  font-weight: 700;
}

.MuiTabs-scroller .MuiTab-textColorPrimary {
  color: #ffffff4f;
}

.MuiTabs-scroller .Mui-selected {
  color: #d9d9d9 !important;
  background-color: transparent !important;
}

.MuiTabs-indicator {
  background-color: #d9d9d9 !important;
}

.MuiTabs-indicator {
  height: 1px !important;
}

/*App Header*/
.MuiAppBar-root {
  box-shadow: 0 0 1.25px #161616 !important;
  border: 0 !important;
}

.profile-icon img {
  width: 24px;
}

.MuiAppBar-root button a {
  color: #fff;
  text-decoration: none;
}

/*
deBar*/
.app-drawer {
  color: #fff;
}

.app-drawer .MuiPaper-root {
  background: transparent !important;
  border: 0 !important;
  box-shadow: 0px 0px 30px #1a1a1b !important;
}

.app-drawer .MuiListItemIcon-root {
  color: #7e7e7e;
}

.ai-logo {
  margin: 16px;
  width: fit-content;
}

/*Side Menu*/
.side-menu {
  width: 80px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: var(--color-primary) ;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-menu .logos {
  display: flex;
  flex-direction: column;
}

.side-menu .logos a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.side-menu .logos h1 {
  font-size: 23px;
}

.side-menu .logos button:before {
  display: none !important;
}

.btn-logout {
  padding: 5px !important;
  min-width: auto !important;
  border-radius: 10px !important;
  background: var(--color-primary) !important;
  width: 34px;
  height: 34px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  margin-top: 10px;
}

.btn-logout:hover {
  border-radius: 10px !important;
  background: var(--color-primary)  !important;
}

.btn-logout:hover::before {
  display: none !important;
}

.btn-logout div {
  margin: 0 !important;
}

/*Statistics*/
.statistics .MuiGrid-item {
  padding-top: 0 !important;
  margin-top: 48px !important;
}

.statistics.MuiGrid-item {
  margin-top: 0 !important;
}

/*Login*/

.login-page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: #fff;

}

.login-page .logo-side {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  text-align: center;
  padding-top: 7%;
  padding-bottom: 1.5%;
  z-index: 2;
}

@media {
  max-width: 728px;
}

{
.login-page .logo-side {
  height: 30vh !important;
}
}

.login-page .logo-side>div:first-child {
  max-width: 60%;
}

#background-video {
  width: 50vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  opacity: 0.2;
  mix-blend-mode: luminosity;
}
@media (max-width: 991px) {
  .login-page .logo-side {
    display: none;

  }
  #background-video{
    display: none;
  }
}
@media (max-width: 728px) {
  #background-video {
    height: 27vh;
  }
}

#background-video::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-primary);
}


.login-logo {
  margin-bottom: 30px;
  max-width: 100%;
  filter: invert(1) brightness(10);
}


.login-page .logo-side h1 {
  font-family: 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #f1f1f1;
}

.login-page .logo-side h5 {
  color: #e8ecef80;
  font-family: 'Karla', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 24px;
  font-weight: 400;
}

.login-page .logo-side h6 {
  color: #e8ecef80 !important;
  font-size: 14px;
  margin-top: 30px;
}
.login-page .login-content{
  /*max-height: 100vh;*/
  /*overflow: auto;*/
}
.login-page .login-content h5,
.login-page .login-content,
.login-page .login-content h6 {
  font-family: 'Cabin', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.login-page .login-content {
  font-family: 'Cabin', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  font-size: 14px;
  color: #afb9c8;
}

@media (max-width: 728px) {
  .login-page .login-content {
    min-height: 73vh;
  }
}

.login-page .login-content .MuiBox-root {
  width: 450px;
  max-width: 90%;
}

.login-page .login-content {
  color: #878ea1;
}

.login-page .login-content h5 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 8px !important;
  font-size: 24px;
}

.login-page .login-content h6 {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 32px;
  font-size: 18px;
}

.login-page .login-content label {
  margin-bottom: 6px;
  color: #e4e9ef;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}

.login-page .login-content a {
  color: var(--color-primary) ;
  display: block;
  margin-bottom: 24px;
  margin-top: 16px;
  text-decoration: none;
}

.login-page .MuiInputBase-root {
  height: 48px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  outline: none !important;
  color: #7e8696;
  border-radius: 10px;
  border: 1px solid #282e35 !important;
}

.login-page .Mui-focused {
  border-color: transparent !important;
}

.login-page .MuiInputBase-root input {
  padding: 14px 20px !important;
  background: transparent !important;
  border: 0 !important;
  border-radius: 10px !important;
  font-size: 14px;
  color: #878ea1 !important;
  outline: none !important;
}

.login-page .MuiInputBase-root.Mui-focused {
  border-color: transparent !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused,
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused:focus {
  outline: none !important;
  box-shadow: none !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
  opacity: 0.6;
}

input:focus {
  outline: none !important;
}

.login-page .MuiInputAdornment-root button.MuiButtonBase-root {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  color: #828a9d;
  display: inherit;
  padding: 0;
}

.login-page .login-content small {
  display: block;
  margin-top: 10px;
  color: #878ea1;
  font-size: 12px;
}

.login-page button {
  border-radius: 12px;
  background: var(--color-primary) ;
  display: block;
  width: 100%;
  align-items: center;
  height: 52px;
  padding: 14px 24px;
  font-size: 16px;
  color: #f1f1f1;
  font-weight: 600;
  text-transform: initial;
}
.login-page button.outlined{
  background-color:  #f1f1f1 !important;
  color: var(--color-primary) ;
}
.text-danger {
  color: #ca0000;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.login-page button:hover {
  background: var(--color-primary) ;
}

.response-icons {
  display: flex;
  gap: 4px;
  margin-top: 24px;
  align-items: center;
}

.response-icons svg {
  font-size: 15px;
  cursor: pointer;
}

.MuiSnackbarContent-action .MuiButton-textSecondary {
  display: none !important;
}

.preview-content {
  height: calc(100vh - 140px);
  margin-top: 10px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio .preview-content {
  height: 100px;
}

.audio.MuiModal-root .MuiBox-root {
  max-width: 550px;
  margin: auto;
  left: auto !important;
  right: auto !important;
  top: auto !important;
}

.audio.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-content>img {
  max-width: 100%;
}

.preview-content>object {
  min-height: 100%;
  height: 100%;
  color: #000;
}

.preview-content>video {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
}



.detections .name {
  position: absolute;
  margin-top: -20px;
  color: #fff;
  font-weight: 500;
  text-transform: capitalize;
  text-shadow: 1px 0 0 BLACK;
}

.detections .detection {
  position: absolute;
  border: 2px solid yellow;
  width: 100%;
  height: 100%;
}

.detections:nth-child(2) .detection {
  border-color: #3d867c;
}

.detections:nth-child(3) .detection {
  border-color: green;
}

.detections:nth-child(4) .detection {
  border-color: purple;
}

.detections:nth-child(5) .detection {
  border-color: red;
}

.detections:nth-child(6) .detection {
  border-color: white;
}

.detections:nth-child(6) .detection {
  border-color: orange;
}

.detections:nth-child(6) .detection {
  border-color: blue;
}

.detections:nth-child(7) .detection {
  border-color: #c2795e;
}

/*Loading*/
.data-loading {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 200px);
}

.data-loading.nodata {
  opacity: 0.5;
}

.charts {
  padding: 20px 0;
  width: 100% !important;
  margin-left: 0 !important;
}

#content .charts {
  padding: 0;
}

.charts .data-loading {
  min-height: 350px;
}

.charts .MuiGrid-item {
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 20px !important;
}

.charts .MuiGrid-item:nth-child(2),
.charts .MuiGrid-item:nth-child(4),
.charts .MuiGrid-item:nth-child(5) {
  padding-right: 0 !important;
}

.position-relative {
  position: relative;
}

.tabs-icons {
  position: absolute;
  top: 4px;
  right: 0;
}

.related {
  padding-right: 64px;
}

.search-img {
  max-width: 230px;
  height: auto;
}

.loading-img {
  max-width: 160px;
  width: 50px;
  height: auto;
}

.charts .loading-img {
  max-width: 130px;
}
.charts .loading-img.loading_small {
  max-width: 30px;
}

.no-data-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 10px;
  font-size: 19px;
  font-weight: 500;
}

.no-data {
  max-width: 170px;
  height: auto;
}

.am5-tooltip-container div {
  text-transform: uppercase !important;
}

.active path {
  stroke: var(--color-primary) !important;
}

/*Chat*/
.css-n1fv8b,
.css-4o9ttt,
.css-1p0hetc {
  max-width: 100% !important;
}

.MuiStack-root {
  background-color: transparent !important;
  border-top: 0 !important;
  margin-top: 0 !important;
}

.css-m1iyop-MuiStack-root {
  margin-top: 0px !important;
}


.gIetUJP {
  font-size: 10px;
}

.css-6965kq {
  color: #d4d8db;
  line-height: 21px;
  font-weight: 400;
}

.css-1p0hetc {
  background: #090c10;
}

.css-n1fv8b {
  padding: 0 70px 300px 16px;
  margin-top: 0 !important;
  top: 70px;
}

.stack>.MuiBox-root {
  padding: 24px 24px 48px 24px;
  border-radius: 16px;
  border: 1px solid #323b3a;
  background: var(--color-primary) ;
  box-shadow: 0px 2px 4px 0px rgba(25, 33, 61, 0.08);
}

.css-1y3mnrk,
.css-6965kq {
  padding: 14px;
}

.css-1y3mnrk {
  background: #15272a;
  width: fit-content;
  margin-left: auto;
  color: #e4e8eb !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 14px 48px 14px 14px !important;
  color: #c7d0dd !important;
  margin-bottom: 25px;
  border-radius: 11px !important;
  border: 1px solid var(--color-primary) !important;
  box-shadow: none !important;
  background-color: #090c10 !important;
}

.css-q8hpuo-MuiFormControl-root {
  position: relative;
}

.chat-btn-send,
.chat-btn-loading {}

.chat-input {
  background-color: transparent !important;
  min-height: 68px;
  padding: 0 0 16px 0 !important;
  margin-bottom: 16px;
  border-bottom: 1px solid #323b3a;
}

.chat-input textarea {
  background: transparent !important;
  background-color: transparent !important;
  border: 0 !important;
  min-height: 68px;
  padding: 0 !important;
  color: #939a99 !important;
  font-size: 14px;
  max-height: 250px;
  overflow-y: auto !important;
}

.stop-response {
  position: absolute !important;
  top: -175px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px !important;
  padding: 6px 24px !important;
  margin-bottom: 12px;
  border-color: var(--color-primary) !important;
}

.stop-response .MuiButton-startIcon {
  margin-right: 4px !important;
}

.MuiBox-root {
  position: relative;
}

.copy-icon {
  font-size: 16px;
  cursor: pointer;
}

.chat-type {
  font-size: 15px;
  font-weight: 500;
}

.chat-color,
.chat-color span {
  color: var(--color-primary) !important;
}

.chat-online,
.chat-online span {
  color: var(--color-primary)  !important;
}

.chat-offline,
.chat-offline span {
  color: #ff0808 !important;
}

.chat-files,
.chat-files span {
  color: #fcfc10 !important;
}

.chat-toc,
.chat-toc span {
  color: #b8b806 !important;
}

.chat-7b,
.chat-7b span {
  color: var(--color-primary)  !important;
}

.chat-13b,
.chat-13b span {
  color: #90218a !important;
}

/*Radar*/
.search-container.radar ul {
  padding: 15px !important;
  z-index: 100;
  position: relative;
}

.sidebar {
  padding: 0 !important;
  margin-top: 30px;
}

.search-container.radar .area-label>rect {
  fill: #2a2d30;
}

.open-dropdown {
  border-radius: 0 0 2px 0;
  background-color: #0f161e !important;
}

.open-dropdown .options-container {
  max-height: 400px;
  overflow-y: auto;
  border: 2px solid #fff;
  border-top: 0;
  background-color: #0f161e !important;
}

.open-dropdown li {
  color: #8e8d8d;
}

.open-dropdown li:hover {
  color: #fff;
}

pre {
  color: #959595 !important;
  font-size: 14px !important;
  line-height: 26px !important;
}

.text-tag {
  background: #eff8ff;
}

ch .text-tag body {
  line-height: 20px !important;
  font-size: 14px !important;
}

.navbar-toggler {
  display: none !important;
  position: fixed !important;
  top: 17px;
  z-index: 10;
  left: 19px;
}

hr {
  opacity: 0.3;
}

.Permission-key {
  min-width: 120px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}

.permissions-names {
  font-size: 13px;
}

.permissions-names span {
  margin-right: 20px;
  opacity: 0.8;
}

/**/

.upload-audio {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: -50px;
}

#uploadFileBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  cursor: pointer;
}

#uploadFileBtn span {
  margin: 0;
}

#uploadFileBtn svg {
  font-size: 23px;
}

#uploadFileBtn.drawer-open path {
  stroke: var(--color-primary) ;
}

.file-upload-progress {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 10px;
}

.file-upload-progress .MuiLinearProgress-bar1Determinate {
  background-color: var(--color-primary)  !important;
}

.file-upload-progress .MuiLinearProgress-barColorSuccess {
  background: var(--color-primary) !important;
}

.file-upload-progress .MuiTypography-body2 {
  color: #666f8d !important;
  position: absolute;
  top: -17px;
  right: 1px;
  font-size: 10px;
}

.MuiDrawer-paper {
  padding: 10px;

  color: #fff !important;
  max-width: 260px;
}

.popover-btn {
  display: flex;
  padding: 10px;
  gap: 10px;
  border: 1px solid #323b3a !important;
  box-shadow: none !important;
  position: relative;
}



.warning-alert .MuiAlert-root {
  background-color: #f3e4e4 !important;
  font-size: 13px;
}

.warning-alert .MuiAlert-root .MuiAlert-icon {
  display: none;
}

.warning-alert .MuiAlert-action {
  width: 62px;
  justify-content: end;
}

.warning-alert .MuiAlert-root .MuiIconButton-root {
  padding: 0 !important;
}

.warning-alert .MuiAlert-message {
  padding: 0;
  color: #000 !important;
  overflow: hidden;
}
.MuiButton-sizeSmall.MuiButtonBase-root {
  padding: 3px 8px !important;
  font-size: 12px;
}
.chat-box,
.stack {
  width: calc(100% - 100px) !important;
  padding-right: 80px;
  padding-left: 80px;
}
.chat-box {
  height: calc(100% - 80px) !important;
}
.stack {
  bottom: 32px;
}
.stack::before {
  position: absolute;
  content: '';
  background-color: var(--color-primary) ;
  height: 195px;
  left: 0;
  width: 100%;
  bottom: -22px;
}
.stack::after {
  position: absolute;
  content: '';
  height: 10px;
  left: 0;
  width: 100%;
  bottom: -34px;
}

.files-drawer {
  padding: 16px;
  position: absolute;
  height: 100%;
  min-height: 100vh;
  width: 260px;
  top: 0;
  bottom: 0;
  left: -100%;
  transition: 0.3s;
  box-shadow: none !important;
  background-color: #1a1c1f !important;
  color: #fff !important;
  z-index: 10;
}

.files-drawer.open {
  left: 0;
}

.drawer-open.chat-box {
  width: calc(100% - 400px) !important;
  margin-left: -278px;
  padding-left: 10px !important;
  padding-right: 0px;
}

.drawer-open.stack {
  width: calc(100% - 392px) !important;
  margin-left: -268px;
  padding-left: 19px !important;
  padding-right: 22px;
}

.stack.drawer-open>.MuiBox-root {
  padding-left: 16px;
}

.drawer {
  right: -100%;
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 16px 26px 16px 16px;
  box-shadow: none !important;
  background-color: var(--color-primary)  !important;
  border-left: 1px solid #333b3b;
  color: #fff !important;
  width: 296px;
  z-index: 2000 !important;
  transition: 0.3s;
}


/*.drawer .drawer-title:hover {*/
/*  background: #192525;*/
/*}*/

.drawer-open.drawer {
  right: 0;
}

.drawer-content {
  padding: 16px;
}

.MuiButton-icon.drawer-open path {
  fill: var(--color-primary) ;
}

.drawer-title h4,
.drawer-title {
  color: #fff !important;
  font-size: 19px !important;
}

.toggle-drawer svg path {
  fill: #fff;
}

.upload-block {
  min-height: 166px;
  text-align: center;
  border: 1px dashed #939a99;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  margin-top: 30px;
}

.upload-block svg {
  font-size: 34px;
}

.upload-block p {
  margin-top: 6px !important;
}

.files-list {
  padding-right: 0;
  padding-left: 0;
  list-style-type: none;
  margin: 4px 0;
  max-height: calc(100% - 230px);
  overflow-y: auto;
}

.files-list li {
  padding: 12px 8px 10px 8px;
  border-radius: 4px;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(135, 142, 165, 0.75);
  color: #939a99;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
  position: relative;
}

.files-list li span {
  word-break: break-all;
  font-size: 13px;
  text-transform: capitalize;
  color: #666f8d;
  font-weight: bold;
}

.file-btns {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 5px;
}

.text-link {
  padding: 1px 4px !important;
  font-size: 10px !important;
  text-transform: capitalize !important;
  height: 24px;
  margin-left: 6px !important;
  min-width: 38px !important;
  display: flex;
  align-items: center;
  gap: 4px;
}

.text-link.loader {
  min-width: 54px !important;
  padding-right: 8px !important;
  position: relative !important;
}

.text-link .typing-loader {
  position: relative;
  left: auto;
  bottom: 8px;
  margin: 0 !important;
  right: 31px;
  opacity: 0.5;
  display: none;
}

.text-link .MuiCircularProgress-root {
  width: 14px !important;
  height: 14px !important;
  color: var(--color-primary) !important;
}
.text-link .MuiCircularProgress-root.spinner_button {
  width: 14px !important;
  height: 14px !important;
  color: #f1f1f1!important;
}

.text-link.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

main.css-plspgo {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.files-list li svg {
  font-size: 15px;
}

#searchRef>div>div>div>div {
  display: none !important;
}

.files-list li svg path {
  /*fill: #b4b4b4;*/
}

.remove-files {
  font-size: 13px !important;
  cursor: pointer;
  opacity: 0.9;
  color: #f10606 !important;
}
.css-1ygil4i-MuiToolbar-root .remove-files{
  padding: 0 !important;
}

button.red-color {
  color: #f10606 !important;
}

.remove-files:hover {
  color: #fc3131 !important;
  opacity: 1;
}

.remove-files path {
  fill: #f10606;
}

.remove-files:hover,
.remove-files:focus {
  opacity: 1;
}

.files-loading {
  height: 6px !important;
  opacity: 0.25;
}

.uploadFilesLoading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #eeeeee26;
  z-index: 2 !important;
}

.cursor-auto {
  cursor: auto !important;
}

.MuiTooltip-popper * {
  text-transform: capitalize !important;
}

.chat-name-setting {
  border-radius: 8px 8px 0 0;
  background: #16191e !important;
  border: 1px solid #262a31 !important;
  position: absolute !important;
  width: 160px;
  right: 0;
  padding: 0 !important;
}

.chat-name-setting .MuiListItemButton-root {
  padding: 6px 12px !important;
}



.audio-record audio {
  height: 36px;
}

.audio-record svg {
  font-size: 20px;
}

.audio-record svg path {
  fill: #939a99;
}

.record-timer {
  color: #939a99;
  font-size: 14px;
  margin-top: 2px;
}

#recordButton {
  height: 36px;
}

#recordButton.recording path {
  fill: red !important;
}

.chat-input.Mui-disabled {
  opacity: 1 !important;
}


.chat-input .MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #939a99 !important;
}

#btnType {
  border-radius: 8px;
  border: 1px solid #323b3a;
  background: var(--color-primary) ;
  box-shadow: 0px 1px 3px 0px rgba(25, 33, 61, 0.1);
  color: #e3e7ed !important;
  padding: 8px 12px;
}

.chat-input.Mui-disabled #btnType {
  opacity: 0.5;
}

.user-message {
  color: #939a99 !important;
}

.user-message>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-message>div path {
  stroke: #939a99 !important;
}

.user-message>div span {
  line-height: 14px;
  display: none;
}

.user-message:hover>div span {
  display: block;
}

.rtl.MuiBox-root {
  direction: rtl;
}

.modal-modal-description {
  padding: 0 10px !important;
  color: #939a99;
  font-family: Cairo;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.96px;
}

.translation .ltr p {
  direction: rtl;
}

.translation .rtl p {
  direction: ltr;
}

/*********** news-letter ************/


thead .MuiTableCell-root {
  border-bottom: 1px solid #364949 !important;
}

tbody .MuiTableCell-root {
  border-bottom: 1px solid var(--color-primary) !important;
}

tbody .MuiTableCell-root button path {
  color: #777e7d9e !important;
}

.custom-form {
  font-family: 'Cabin', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.custom-form label {
  color: #ffffffab;
  font-weight: 500;
  font-size: 14px;
}

.custom-form legend {
  display: none !important;
}

.custom-form .MuiOutlinedInput-input {
  margin: 0 !important;
  padding: 12px 20px !important;
  border: 0 !important;
  border-radius: 10px !important;
  background: var(--color-primary) !important;
  font-size: 13px;
  color: #939a99 !important;
}

.custom-form .MuiOutlinedInput-input:hover {
  border: 0 !important;
}

.custom-form .MuiOutlinedInput-input.MuiSelect-select .MuiBox-root {
  padding: 0 !important;
  background: transparent !important;
  border: 0 !important;
  margin: 0;
}

.custom-form .MuiOutlinedInput-input.MuiSelect-select fieldset {
  display: none;
}

.custom-form .MuiOutlinedInput-input .MuiModal-root .MuiBox-root {
  padding: 0;
  background: transparent;
}

.custom-form .btn-default {
  border-radius: 12px;
  border: 1px solid var(--color-primary) ;
  background: var(--color-primary) ;
  font-size: 16px;
  color: #fefefe;
}

.btn-default-outlined {
  border: 1px solid rgb(87 97 107 / 50%) !important;
  color: #dbdbdb !important;
  border-radius: 8px;
  text-transform: capitalize !important;
}

.custom-form .btn-default {
  border-radius: 10px !important;
  background: var(--color-primary)  !important;
}

.css-1e3jsob-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: var(--color-primary);
}

.multi-input {
  display: flex;
  align-items: center;
  gap: 20px;
}

.custom-form .multi-input button {
  color: #777e7d9e !important;
}

.custom-form .multi-input button.first {
  /*margin-top: 34px;*/
}

.custom-form .multi-input button.first.delete {
  display: none;
}

.custom-form .text-danger {
  color: #ca0000bf;
  font-weight: 400;
  display: inline-block;
}

.MuiButtonGroup-grouped span {
  margin: 0;
}

.alert-error .MuiPaper-root {
  background: rgb(253, 237, 237) !important;
  color: rgb(95, 33, 32) !important;
}

.alert-error * {
  color: rgb(95, 33, 32) !important;
}

.frequentlyDayNum {
  padding: 4px;
  border: 1px solid var(--color-primary);
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
  position: absolute;
  background: var(--color-primary);
  z-index: 10;
  border-radius: 4px;
  top: -139px;
  left: 52px;
  color: #ababab;
  font-weight: 400;
  font-size: 13px;
  display: none;
}

.frequentlyDayNum.show {
  display: grid;
}

.frequentlyDayNum span {
  padding: 0px 6px;
  cursor: pointer;
}

.frequentlyDayNum span.selected {
  background: var(--color-primary) ;
  color: #fff;
}

/*loading 1*/

.load {
  position: relative;
  width: auto;
  height: 30px;
  margin: 0;
}

.load span {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(52, 152, 219, 0.5);
  border-radius: 50%;
  left: 20px;
  bottom: 0;
  animation: snake 1s infinite ease-in-out;
}

.load span:nth-child(2) {
  left: 36px;
  animation-delay: 0.2s;
}

.load span:nth-child(3) {
  left: 52px;
  animation-delay: 0.4s;
}

.load span:nth-child(4) {
  left: 68px;
  animation-delay: 0.6s;
}

.load span:nth-child(5) {
  left: 84px;
  animation-delay: 0.8s;
}

@keyframes snake {

  0%,
  100% {
    opacity: 0.3;
    transform: translateY(0px);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  }

  50% {
    opacity: 1;
    transform: translateY(-10px);
    /*background: rgba(٤٦, ٢٠٤, ١١٣, 0.7);*/
    background: var(--color-primary) ;
    box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.02);
  }
}

.boot-message {
  word-break: break-word;
  color: #939a99 !important;
  border-radius: 16px !important;
}

.error-message .boot-message {
  background: rgb(253, 237, 237) !important;
  color: rgb(95, 33, 32) !important;
}

.error-message .boot-message path {
  stroke: rgb(95, 33, 32) !important;
}

.boot-message a {
  color: var(--color-primary) ;
  text-decoration: var(--color-primary) ;
  line-height: 24px;
}

.boot-message .toc-title {
  color: #dcdcdc;
  margin-bottom: 4px;
  display: block;
}

.boot-message a:hover {
  /*color: #0a917f;*/
  color: var(--color-secondary);
}

.boot-message .MuiMobileStepper-root {
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  padding: 8px 0;
  margin-left: -6px;
  color: #a1a1a1;
  font-size: 13px;
}

.boot-message button {
  padding: 0 !important;
  min-width: fit-content;
}

.boot-message button.Download {
  padding: 5px 15px !important;
}

.boot-message button.Download svg {
  color: #fff;
}

.good-response,
.bad-response {
  color: #fff;
}

.Mui-disabled {
  opacity: 0.6;
  color: #fff !important;
}

.boot-message button svg {
  font-size: 21px;
  color: #a1a1a1;
}

.boot-message .MuiMobileStepper-root .MuiButton-text svg {
  width: 20px !important;
}

/*dd*/
.preview-loading {
  display: flex;
  align-items: center;
  font-size: 13px;
  position: absolute;
  right: -12px;
  background: var(--color-primary);
  width: 215px;
  padding: 8px;
  border-radius: 3px;
  color: #c6c6c6 !important;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft {
  right: 72px !important;
  left: auto !important;
}
.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft.top{
  top: 89px;
  height: fit-content;
}
.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft.center{
  right: calc(50vw - 146px) !important
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft[color='info'] .MuiPaper-root {
  background-color: var(--color-primary) !important;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft[color='info'] .MuiPaper-root * {
  color: #fff !important;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft[color='warning'] .MuiPaper-root {
  background-color: #fff !important;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft[color='warning'] .MuiSnackbarContent-message {
  padding: 0 !important;
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft[color='warning'] .MuiPaper-root * {
  color: #2b2a2a !important;
}

/*Chat sessions start*/
.chat-full {
  display: flex;
}

.chat-content {
  width: 100%;
  position: relative;
}

.chat-content.sidebar-open {
  width: calc(100% - 240px);
}

.chat-sessions {
  width: 0;
  overflow: hidden;
}

.sessions-card {
  background: transparent !important;
  box-shadow: none !important;
  border: none transparent !important;
  transition: 0.3s all ease-in-out;
}

.button-content {
  display: flex;
  align-items: center;
  gap: 150px;
}

.sessions-card:hover {
  background: rgba(236, 235, 235, 0.4) !important;
}

.sessions-card:hover .chat-options {
  opacity: 1 !important;
}

.chat-sessions.sidebar-open {
  width: 240px;
  border-left: 1px solid #aeaeaf;
  height: 100%;
}

.stack.sidebar-open {
  width: calc(100% - 340px) !important;
  padding-right: 26px;
  padding-left: 18px;
}

.sidebar-open.chat-box {
  width: 100% !important;
}

.sessions-content {
  padding: 16px 10px;
}

.sessions-content,
.sessions-content .files-list {
  height: 100%;
  max-height: 100vh;
  width: 235px;
}

.chat-options {
  opacity: 0 !important;
  transition: 0.3s all ease-in-out;
}

.chat-name-setting {
  border-radius: 0 0 8px 8px;

  z-index: 10;
  box-shadow: 0px 0px 16px #d2d2d226;
}

.chat-name-setting.dark {
  background: var(--color-primary) !important;
}

.chat-name-setting.light {
  background: #fff !important;
}

.css-2sm3ha-MuiButtonBase-root-MuiListItemButton-root.Mui-selected:hover {
  background-color: var(--color-primary) ;
}

.toggle-chat-sidebar.MuiIconButton-root {
  color: #939a99 !important;
}

.editable-input {
  border: 0;
}

.editable-input input {
  padding: 0;
  background: transparent !important;
  border: 0 !important;
  padding-left: 0 !important;
  font-size: 13px;
}

.editable-input .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

/*Chat sessions end*/

/*loading 2*/
.typing-loader {
  margin-left: 12px;
  filter: invert(100%);
  width: 8px;
  height: 7px;
  -webkit-animation: line 1s linear infinite alternate;
  -moz-animation: line 1s linear infinite alternate;
  animation: line 1s linear infinite alternate;
  bottom: 205px;
  left: calc(50% - 14px);
  position: absolute;
}

.typing-loader.file {
  position: relative;
  bottom: auto;
  left: auto;
  filter: invert(0.8);
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft[color='info'] .preview-loading .typing-loader {
  margin-left: 5px;
  margin-top: 6px;
  filter: invert(71%);
}

.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomLeft[color='info'] .typing-loader {
  margin-right: 25px;
  margin-left: -5px;
}

@-webkit-keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2), 24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

@-moz-keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2), 24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

@keyframes line {
  0% {
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2), 24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  50% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 2), 24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }

  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px rgba(0, 0, 0, 2);
  }
}

/*Radar loader*/

.spinner {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
}

.spinner .ball {
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color: var(--color-primary) !important;
  border-radius: 50%;
  display: inline-block;
  animation: motion 3s cubic-bezier(0.07, 0.5, 0.53, 0.88) infinite;
  opacity: 1;
}

.spinner p {
  color: var(--color-primary) !important;
  opacity: 1;
  margin-top: 10px;
  letter-spacing: 3px;
  font-size: 14px;
  font-weight: 600;
}

@keyframes motion {
  0% {
    transform: translateX(0) scale(1);
  }

  25% {
    transform: translateX(-50px) scalel(0.3);
  }

  50% {
    transform: translateX(0) scale(1);
  }

  75% {
    transform: translateX(50px) scale(0.3);
  }

  100% {
    transform: translateX(0) scale(1);
  }
}

.chat-newsletter-answer {
  box-shadow: 0 0 5px #ccc !important;
}


/****************** Landing page ************************/
.landing-page .top-section {
  font-family: 'Cabin', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  padding: 60px 24px 60px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landing-page .top-section span {}

.landing-page .top-section img {
  opacity: 0.9;
  max-width: 100%;
}

.landing-page h1,
.landing-page h2 {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-size: 45px;
  font-weight: 800;
}

.landing-page h2 {
  margin-bottom: 40px;
}

.landing-page p {
  color: #d9d9d9 !important;
}

.landing-page .top-section>div:first-child {
  width: 50%;
}

.landing-page .top-section>div:last-child {
  width: 50%;
  text-align: center;
  padding: 10px;
}

.landing-page .sec-section .card {
  background: #ffffff;
  padding: 20px;
  color: #747f7e;
  line-height: 24px;
}

.landing-page .sec-section .card img {
  width: 27px;
}

.landing-page .sec-section .card .card-title {
  color: #000;
}

.landing-page .sec-section .card button {
  margin-top: 20px;
}

.landing-page .sec-section {
  border-radius: 0 !important;
  margin: -10px;
  background: var(--color-primary) ;
  padding: 24px;
}

.landing-page .sec-section h3,
.third-section h3 {
  font-size: 27px;
  font-weight: 700;
  color: #f1f1f1 !important;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.third-section {
  text-align: center;
  color: #000 !important;
  margin-top: 60px;
}

.numbers {
  text-align: left;
  width: fit-content;
  margin: auto;
}

.third-section h4 {
  color: #f1f1f1 !important;
  font-size: 40px !important;
  font-weight: 600;
}

.third-section h5 {
  color: #6c7278 !important;
  font-size: 16px;
}

.third-section span {
  color: var(--color-primary) ;
}

.MuiButton-outlinedPrimary {
  border: 1px solid var(--color-primary)  !important;
  color: var(--color-primary) !important;
}

.sec-section.question {
  background-color: transparent;
}

.landing-page .sec-section.question .card .card-title {
  color: #f1f1f1;
}

.landing-page .sec-section.question .card .card-body {
  color: #a7a7a7;
}

.sec-section.question .card {
  margin-bottom: 20px;
  background-color: var(--color-primary)  !important;
}

table .MuiChip-filled {
  margin-bottom: 4px;
}

@media screen and (min-width: 1400px) {}

@media screen and (max-width: 1240px) {
  .login-page .logo-side h1 {
    font-size: 38px;
    color: #f1f1f1;
  }

  .login-page .logo-side h5 {
    font-size: 18px;
    color: #f1f1f1 !important;
  }

  .login-page .login-content h5 {
    font-size: 21px;
  }

  .login-page .login-content h6 {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .login-page .logo-side h1 {
    font-size: 32px;
  }

  .login-page .logo-side h5 {
    font-size: 15px;
  }

  .login-page .login-content h5 {
    font-size: 21px;
  }

  .login-page .login-content h6,
  .login-page .login-content label,
  .login-page .MuiInputBase-root input {
    font-size: 12px;
  }

  .login-page .login-content small {
    font-size: 9px;
  }

  .login-page .MuiInputBase-root {
    height: 42px;
  }

  .login-page button {
    height: 42px;
    padding: initial;
  }

  .audio-record audio {
    position: absolute;
    top: -63px;
    left: -3px;
    max-width: calc(100% - 4px);
    width: calc(100% - 4px);
  }

  .landing-page h1,
  .landing-page h2 {
    font-size: 34px;
  }

  .landing-page .top-section {
    padding-top: 20px;
  }
}

@media screen and (max-width: 900px) {
  .charts .MuiGrid-item {
    padding-right: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .search-pagination {
    flex-direction: column;
  }

  .all-results {
    margin-bottom: 12px;
  }

  .chat-box,
  .stack {
    padding-right: 20px;
    padding-left: 20px;
  }

  .drawer-open.stack,
  .drawer-open.chat-box {
    width: calc(100% - 101px) !important;
    margin-left: 10px !important;
  }

  .drawer-open.drawer {
    z-index: 10;
  }

  .login-page {
    flex-direction: column;
  }

  .login-page .logo-side,
  .login-page .login-content {
    width: 100%;
  }

  #background-video {
    width: 100vw;
  }

  .login-page .logo-side h6 {
    display: none;
  }

  /*login-page .MuiBox-root {*/
  /*  width: min-content;*/
  /*}*/
  .data-block .block-head {
    flex-direction: column;
    overflow: hidden;
  }

  .data-block .file-icons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 11px;
  }

  .data-block .block-head {
    margin-right: 0;
  }

  .data-block .block-foot {
    flex-direction: column;
    justify-content: space-between;
    align-items: normal;
  }
  /*.data-block .block-foot {*/
  /*  flex-direction: column;*/
  /*  justify-content: space-between;*/
  /*  align-items: self-end;*/
  /*}*/

  .landing-page .top-section>div:first-child {
    width: 100%;
  }

  .landing-page .top-section>div:nth-child(2) {
    display: none;
  }

  .landing-page .top-section {
    padding-top: 60px;
  }

  .landing-page h2 {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 676px) {
  .search-top .MuiLink-root {
    display: none;
  }

  .search-top.search {
    padding: 16px 12px;
  }

  .search-top.search .flex-gap-regular {
    gap: 8px;
  }

  #content {
    padding: 0 10px;
  }

  .data-block .file-icons {
    padding-right: 10px;
  }

  .related-searches p {
    margin-bottom: 10px;
    display: inline-block;
  }

  .related-searches {
    display: block;
  }

  @media (max-width:728px) {
    .related-searches {
      flex-flow: wrap;
    }
  }

  .chat-btn-send {
    height: 34px;
    padding: 4px;
    min-width: 44px !important;
  }

  .send-message {
    display: none;
  }

  .chat-btn-send .MuiButton-startIcon {
    margin: 0 !important;
  }

  .landing-page .sec-section h3,
  .third-section h3,
  .landing-page .sec-section.question h3 {
    font-size: 24px;
  }

  .landing-page .card-body {
    font-size: 13px;
  }

  .landing-page .sec-section .card button {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .search-popover .css-1ursewb-MuiPaper-root-MuiPopover-paper {
    transform: translate(-5px, 10px) !important;
  }
  .data-block .block-foot {
    flex-direction: column-reverse;
    justify-content: center;
    gap:20px;
    align-items: center;
  }
  .MuiModal-root .MuiBox-root{
    padding-inline: 10px;
  }

  .third-section h4 {
    font-size: 34px;
  }

  .MuiPaginationItem-root,
  .MuiPagination-root button {
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .login-page .logo-side h1 {
    font-size: 29px;
  }

  .navbar-toggler {
    display: block !important;
  }

  .title-taps {
    margin-top: 48px;
  }

  .side-menu,
  .theme-btn,
  #lang-btn {
    display: none;
  }

  .side-menu {
    top: 54px;
  }

  .mobile-open.side-menu {
    display: flex;
  }

  .search-container {
    margin-left: 0;
    width: 100%;
  }

  .chat-box,
  .stack {
    width: calc(100% - 40px) !important;
    padding-right: 0;
    padding-left: 0;
    left: 20px;
    right: 30px;
  }

  /*.chat-btn-send{*/
  /*  display: none !important;*/
  /*}*/
  .stack>.MuiBox-root {
    padding-left: 10px;
    padding-right: 10px;
  }

  .search-top.search {
    width: calc(100% - 20px);
  }

  .search-top.search .flex-gap-regular {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-top.search .page-title {
    padding-left: 42px;
    color:var(--color-primary)  !important;
  }
}

.wakeb_live_button {
  color: white !important;
}


.new-chat {
  color: #fff !important;
}

.sidebar-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eae9e9 !important;
}

.sidebar-icon-button {
  margin-top: 7px;
  margin-inline: 10px !important;
}

.css-uob957-MuiListSubheader-root {
  background-color: transparent !important;
  font-size: 12px !important;
}

.logout-button {
  width: 100% !important;
  position: absolute;
  bottom: 15px;
  left: 16px;
}

.drawer-lists {
  position: relative;
}

div.logout-button .MuiPaper-root .MuiButtonBase-root {
  width: 100% !important;
}

.css-1ramet4 {
  margin-right: 0 !important;
}

.content-text {
  display: flex;
  justify-content: space-between;
}

.QOfBG {
  padding: 0 !important;
}

Button .text {
  color: white !important;
  font-size: 15px !important;
}

div span.btn-text {
  color: #fff !important;
}

.textarea {
  --chat-input-box-height: 280px;

  background-color: transparent !important;
  box-shadow: 0px 0px 3px rgba(158, 156, 156, 0.45) !important;
  border: 0 !important;
  padding: 15px !important;
  outline: none !important;
  color: #898787 !important;
  position: absolute !important;
  top: 33rem !important;
  width: 100% !important;
  margin: 0 auto !important;
  z-index: 999;
  top: calc(100vh - var(--chat-input-box-height)) !important;
}

@media (min-width: 1550px) {
  .textarea {
    top: calc(100vh - var(--chat-input-box-height)) !important;
  }
}

.textarea-modal {
  background-color: transparent !important;
  border: 0 !important;
  padding: 15px !important;
  outline: none !important;
  color: #898787 !important;
  width: 100% !important;
  margin: 0 auto !important;
}

.textarea-modal:focus {
  outline: none !important;
}

.search-animation {
  font-size: 15px;
  color: var(--color-primary) !important;
}

.css-q2at9c {
  background-color: transparent !important;
}

.css-1q0cp79 {
  background-color: transparent !important;
}

.hide-scroll {
  scrollbar-width: none !important;
}

.Mui-focused .css-sws92j-JoyTextarea-textarea {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.chat-message:hover .edit-icon-button {
  visibility: visible !important;
}

.MuiListItemText-root.session-list {
  margin-inline: 0 !important;
}

.Mui-focused {
  border: 0 !important;
}

.css-9ywadu-JoyTextarea-root:not([data-inverted-colors='false']) {
  --Textarea-focusedHighlight: rgba(11, 119, 107, 0) !important;
}

.files-upload {
  display: flex !important;
  align-items: center !important;
}

.main-content {
  max-height: 100vh !important;
  width: 100% !important;
}

.chat-container {
  --chat-input-box-height: 280px;
  --margin-above-chat-input: 20px;

  max-height: calc(100vh - var(--chat-input-box-height) - var(--margin-above-chat-input)) !important;
  overflow-y: auto;
}

.chat-container::-webkit-scrollbar {
  display: none;
}

div.MuiTabs-scroller .Mui-selected {
  background-color: var(--color-primary)  !important;
  color: #f1f1f1  !important;
  border-radius: 8px !important;
  border: none !important;
  /*padding: 5px !important;*/
  min-width: 50px !important;
}

.MuiTabs-scroller .MuiTab-textColorPrimary {
  font-weight: bold !important;
  color:  var(--color-primary)  !important;
}

.MuiTabs-indicator {
  display: none !important;
}

.css-1bl9gvt-MuiButtonBase-root-MuiTab-root {
  padding: 5px 10px !important;
}

.remove-padding {
  padding: 0 !important;
}

.css-q07lxu-MuiChip-root {
  background-color: var(--color-primary)  !important;
}

div.css-h93ljk-MuiTypography-root {
  /*color: #000 !important;*/
  font-weight: bold;
  margin-top: 20px;
}

.image-chat {
  width: 22px;
  height: 22px;
}

.suggestion {
  width: 100%;
  display: flex;
  justify-content: center !important;
}

.MuiListItemText-root,
.card-title,
.result-container .data-block h3 {
  text-align: start;
  margin-inline: 20px;
}

.css-og1k9e {
  margin-left: 0 !important;
}

.css-rk9bcr {
  margin-right: 0 !important;
}

.MuiBox-root button.sign-button {
  color: #fff !important;
  padding: 15px 40px !important;
}

.css-hyum1k-MuiToolbar-root {
  min-height: 80px !important;
}

.logo-landing {
  width: 200px;
}

/*hero section*/
.chat-hero {
  position: absolute;
  top: 8%;
  left: 15%;
}

.hero-chat-image {
  width: 15rem;
}

.hero-sub-text {
  display: flex;
  justify-content: center;
  width: 50%;
}

.custom-Container {
  padding: 0 100px !important;
}

/*loader animation*/
@keyframes moveLeftRight {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0);
  }
}

.top-line {
  width: 70%;
  margin-left: 100px;
}

.bottom-line {
  width: 75%;
}

.robot-image {
  animation: moveLeftRight 3s ease-in-out infinite;
}

.robot-image-loader {
  animation: moveLeftRight 3s ease-in-out infinite;
}

.loader-text {
  color: rgba(103, 212, 56, 1);
  font-size: 25px;
  font-weight: bold;
}

/*features section*/
.section-title {
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: start;
}

.title-box {
  border: 3px solid rgba(103, 212, 56, 1);
  width: fit-content;
  padding: 0 20px;
  height: 86px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(8, 89, 80, 1) 0%, rgba(103, 212, 56, 1) 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 56px;
  font-weight: bold;
  position: relative;
}

.title-top-right {
  position: absolute;
  top: -5px;
  right: -5px;
}

.title-top-left {
  position: absolute;
  top: -5px;
  left: -5px;
}

.title-bottom-left {
  position: absolute;
  bottom: -5px;
  left: -5px;
}

.title-bottom-right {
  position: absolute;
  bottom: -5px;
  right: -5px;
}

/*unstructured-section*/
.unstructured-section {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 50px;
}

.unstructured-img {
  width: 40% !important;
}

.unstructured-bg {
  position: absolute;
  right: -150px;
  top: -50px;
}

.accordion-section {
  width: 55%;
}

/*search section */
.search-right-pattern {
  position: absolute;
  bottom: 0;
  right: -100px;
}

/*chatting section*/
.card-up {
  transform: translateY(-40px);
}

.left-chat-pattern {
  position: absolute;
  left: -170px;
  top: 150px;
}

/*some-of-featured*/
.some-of-featured-pattern {
  position: absolute;
  right: -150px;
  top: 0;
}

#num11,
#num22,
#num33,
#num44,
#num55,
#num66 {
  animation: moveLeftRight 3s ease-in-out infinite;
}

/*media query*/
@media (max-width: 991px) {
  .logo-landing {
    width: 150px;
  }

  .title-box {
    font-size: 40px;
  }

  .custom-Container {
    padding: 0 50px !important;
  }

  .robot-image {
    position: absolute;
    top: 250px;
    right: 38px;
    width: 80px;
  }

  .chat-hero {
    top: 90px;
    width: 100px !important;
  }

  .top-line {
    margin-left: 0;
    margin-top: 100px;
  }

  .bottom-line {
    width: 90%;
  }

  .unstructured-section {
    flex-direction: column;
  }

  .unstructured-img {
    width: 100% !important;
    margin-bottom: 50px;
  }

  .accordion-section {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .title-box {
    font-size: 25px;
  }

  .custom-Container {
    padding: 0 20px !important;
  }
}

@media (max-width: 400px) {
  .robot-image {
    top: 180px;
  }

  .chat-hero {
    top: 70px;
    width: 100px !important;
  }

  .custom-Container {
    padding: 0 10px !important;
  }
}

.dark .dark-mode-color .MuiSvgIcon-root {
  color: #939a99 !important;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
}

button.main-color {
  color: var(--color-primary) !important;
}

.pl-4 {
  padding-left: 10px !important;
}

.pagination-wrapper .MuiPaginationItem-root {
  border-radius: 6px;
  background: hsla(254, 14%, 32%, 0.08);
  border: 0;
  color: hsla(254, 14%, 32%, 1) !important;
  margin-inline: 2px;
  /*margin-bottom: 20px;*/
  margin-bottom: 10px;
}

.pagination-wrapper .MuiPaginationItem-root.Mui-selected {
  background-color: var(--color-primary) !important;
  color: #fff !important;
}

.pagination-wrapper li,
.pagination-wrapper li div.MuiPaginationItem-root {
  height: 86%;
}
ol.BasicCard{
  border: 1px solid #ccc !important;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 17px
}
ol.BasicCard span {
  /* margin-bottom: 6px; */
}
ol.BasicCard p {
  /* padding: 4px; */
  font-weight: bold;
}
.MuiPaper-root .MuiChip-filled.badge span {
  color: #f1f1f1 !important;
  opacity: 1 !important;
}
.MuiPaper-root .MuiChip-filled.badge_small span {
  color: #000 !important;
  opacity: 1 !important;
}
#email-select .MuiInputBase-root,
#email-select  fieldset {
  border:  0 !important;
}
#email-select  .MuiOutlinedInput-input{
  font-size: 12px;
  padding-right: 0;
}
/*#email-select svg{*/
/*  display: none;*/
/*}*/
.email-select .MuiList-root{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.Mui-selected{
  color: #fff !important;
}
.email-select {
  font-size: 14px;
  width: 100px;
  margin-left: 5px;
}
.MuiPaper-root  .status {
  color: #fff !important;
}
.MuiPaper-root  .status_aiServey span {
  color: #fff !important;
}

#email-select{
  border-left: 2px solid #cfd3d8;
}
.email-select li{
  font-size: 13px;
}
#email-select .MuiSelect-icon{
  display: none !important;
}
#email-select .MuiSelect-iconOpen{
  display: block !important;
  right: -3px;
  top: 30px;
}
.text-info{
  color: #b57500;
}

.statistics-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.card_statistic {
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  text-align: center;
  border: 1px solid var(--color-primary) ;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
}

.card_statistic p {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: var(--color-primary)  !important;
}
.card_statistic h3 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
  color: var(--color-primary)  !important;
}

.card_statistic h3 svg {
  font-size: 1.5em; /* Adjust icon size */
}
@media (max-width: 1024px) {
  .statistics-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* For mobile (1 card per row) */

.MuiGrid-item.statistics-cards-grid{
  padding-right: 0 !important;
}

.reset_form{
  /*margin: 100px;*/
}
.css-1dqfec5{
  min-width: 400px;
}
.form_content_reset{
  width: 50%;
  height: 100vh;
  background-color:#fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .statistics-cards {
    grid-template-columns: 1fr;
  }
  .reset-video{
    height: 20% !important;
  }
  .form_content_reset{
    width: 100%;
    height: 70vh;
  }
  .reset_form{
    display: none;
  }
  .css-1dqfec5{
    min-width: 300px;
  }

}
.MuiPaper-root div.MuiSnackbarContent-message {
  color: #fff !important;
}
.MuiDateRangeCalendar-root >div{
  display: none;
}
.MuiDateRangeCalendar-root >div.MuiDateRangeCalendar-monthContainer{
  display: block;
}
.date-range .MuiOutlinedInput-input{
  background: #fff !important;
  border: 0 !important;
}

.MuiBox-root .nav_title{
  color: #838282 !important;
  font-weight: bolder;
  font-size: 11px;
  margin-left: 6px;
}
.create-newsletter-btn{
  color: #f1f1f1 !important;
}
.create-newsletter-btn_cancel{
  background-color: #f00 !important;
  color: #fff !important;
}
.max-size{
  font-weight: 600;
  color: #687674 !important;
  font-size: 11px !important;
}
.MuiDialog-root.dialog_layer{
  z-index: 3000 !important;
}
.MuiDialog-root h2{
  color: #000 !important;
  font-weight: 600;
}
.MuiButton-contained.MuiButton-containedDanger,
.MuiButton-variantContained.danger,
.MuiButton-contained.danger{
  background: #c34343 !important;
  color: #fff !important;
  box-shadow: none !important;
}
.MuiButton-contained.MuiButton-containedDanger:hover,
.MuiButton-variantContained.danger:hover,
.MuiButton-contained.danger:hover{
  background: #963b3b !important;
}
.MuiDialogActions-spacing{
  padding: 0 24px 16px 24px !important;
  justify-content: flex-start !important;
}
.MuiDialogActions-root .MuiButton-root{
  min-width: 78px;
  text-transform: capitalize !important;
  border-radius: 6px !important;
}
.MuiDialog-container .MuiPaper-root{
  max-width: 90vw;
  min-width: 400px;
  border-radius: 8px;
}
.MuiDialog-container p{
  color: #686868 !important;
}
.files-list .delete{
  background: #e8505030;
}
.files-list .delete:hover{
  background: #e850504d;
}
.files-list .show{
  background: #337c7a1c;
}
.files-list .show:hover{
  background: #337c7a40;
}

.files-list .summary {
  background: var(--color-primary) ;
  color: #f1f1f1;
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.files-list .summary:hover{
  background: var(--color-primary) !important;
}

.loading-img-pag{
  max-width: 160px;
  width: 50px;
  height: 50px;
  margin-top: 20%;
  position: relative;
  z-index: 999;
}

.search-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.005);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 1;
  backdrop-filter:blur(3px);
}
.search-parent-loadder  {
  position: relative !important;
}
.resources_card{
  padding: 0 !important;
}
.loader-container {
  color: white !important;
}
.modal_textarea{
  background-color: #fafafa !important;
  max-height: 200px !important;
  overflow: auto;
}
.modal_textarea_box{
  background-color: #fafafa !important;

}
.files-list .delete{
  background: #e8505030;
}
.files-list .delete:hover{
  background: #e850504d;
}
.files-list .delete svg{
  color: #b52f2f;
}
.files-list .show{
  background: #337c7a1c;
}
.files-list .show:hover{
  background: #337c7a40;
}

.files-list .MuiButton-root.summary p{
  color: #666f8d !important;
  display: flex;
  align-items: center;
}
.files-list .summary:hover{
  background:  var(--color-primary) !important;
}
.files-list .summary span{
  margin-right: 4px;
}
.files-list .summary svg{
  color: #eee !important;
}
.files-list .summary.Mui-disabled{
  background: var(--color-primary)  !important;
}
.files-list .MuiButton-root{
  min-height: 28px !important;
  height: 28px !important;
  padding-right: 6px !important;
  padding-left: 6px !important;
  min-width: auto !important;
}
.MuiChip-filled.active_chip span{
  color: #f1f1f1 !important;
}
.translate-message  .message .MuiSnackbarContent-message{
  color:  var(--color-primary) !important;
}
.translate-message  .message .MuiPaper-root{
  border: 1px solid  var(--color-primary) !important;

}

.markdown-container table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
}

.markdown-container th, .markdown-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.markdown-container th {
  background-color: #f4f4f4;
  font-weight: bold;
}
pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em
}
code.hljs {
  padding: 3px 5px
}

/*!
  Theme: GitHub Dark
  Description: Dark theme as seen on github.com
  Author: github.com
  Maintainer: @Hirse
  Updated: 2021-05-15

  Outdated base version: https://github.com/primer/github-syntax-dark
  Current colors taken from GitHub's CSS
*/
.markdown-container pre {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}
.markdown-container code {
  border-radius: 5px;
}
.markdown-container code, .markdown-container code span{
  font-family: 'Fira Code', Consolas, Menlo, Monaco, "Courier New", monospace!important;
 }
.hljs {
  color: #c9d1d9;
  background: #0d1117
}
.hljs-doctag,
.hljs-keyword,
.hljs-meta .hljs-keyword,
.hljs-template-tag,
.hljs-template-variable,
.hljs-type,
.hljs-variable.language_ {
  /* prettylights-syntax-keyword */
  color: #ff7b72
}
.hljs-title,
.hljs-title.class_,
.hljs-title.class_.inherited__,
.hljs-title.function_ {
  /* prettylights-syntax-entity */
  color: #d2a8ff
}
.hljs-attr,
.hljs-attribute,
.hljs-literal,
.hljs-meta,
.hljs-number,
.hljs-operator,
.hljs-variable,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-id {
  /* prettylights-syntax-constant */
  color: #79c0ff
}
.hljs-regexp,
.hljs-string,
.hljs-meta .hljs-string {
  /* prettylights-syntax-string */
  color: #a5d6ff
}
.hljs-built_in,
.hljs-symbol {
  /* prettylights-syntax-variable */
  color: #ffa657
}
.hljs-comment,
.hljs-code,
.hljs-formula {
  /* prettylights-syntax-comment */
  color: #8b949e
}
.hljs-name,
.hljs-quote,
.hljs-selector-tag,
.hljs-selector-pseudo {
  /* prettylights-syntax-entity-tag */
  color: #7ee787
}
.hljs-subst {
  /* prettylights-syntax-storage-modifier-import */
  color: #c9d1d9
}
.hljs-section {
  /* prettylights-syntax-markup-heading */
  color: #1f6feb;
  font-weight: bold
}
.hljs-bullet {
  /* prettylights-syntax-markup-list */
  color: #f2cc60
}
.hljs-emphasis {
  /* prettylights-syntax-markup-italic */
  color: #c9d1d9;
  font-style: italic
}
.hljs-strong {
  /* prettylights-syntax-markup-bold */
  color: #c9d1d9;
  font-weight: bold
}
.hljs-addition {
  /* prettylights-syntax-markup-inserted */
  color: #aff5b4;
  background-color:  var(--color-primary)
}
.hljs-deletion {
  /* prettylights-syntax-markup-deleted */
  color: #ffdcd7;
  background-color: #67060c
}
.hljs-char.escape_,
.hljs-link,
.hljs-params,
.hljs-property,
.hljs-punctuation,
.hljs-tag {
  /* purposely ignored */

}


/* Animated Character Styling */
.animate-charcter {
  text-transform: capitalize;
  background-image: linear-gradient(
    -225deg,
    #c7c7c6 0%,
    #eaeaea 29%,
    #757777 67%,
    #c4c9c6 100%
  );
  background-size: 200% auto; /* Optimized to avoid redundancy */
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
  font-size: 16px;
}

/* Text Clip Animation */
@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.status_chip span{
  color: #fff !important;
}
.MuiPaper-root span.percentage-human{
  color:green !important;
  font-size: 25px !important;
}
.MuiPaper-root span.percentage-machine{
  color:red !important;
  font-size: 25px !important;
}

/* Message Container */
.message-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-primary) ;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  z-index: 1000000;
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
  height: 70px;
  visibility: hidden;
}

/* Show Message */
.message-container.visible {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

/* Hide Message */
.message-container.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%) translateY(20px);
}

/* Close Button */
.close-button {
  background: transparent;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
}

.close-button:hover {
  color: #ff6b6b;
}
.text-capitalize{
  text-transform: capitalize;
}
.css-rddc7b-MuiButtonBase-root-MuiButton-root span{
  color: #fff !important;
}
.MuiTypography-root.upload-title{
  color: #000 !important;
}


.pulse {
  width:12px;
  height: 12px;
  border-radius: 50%;
  background: var(--color-primary)  ;
  box-shadow: 0 0 0   var(--color-primary) ;
  animation: pulsing 1s infinite;
  transition: all 0.2s;
  cursor: pointer;
  margin-left: 20px;
}
.pulse:active {
  transform: scale(1.2);
}

@keyframes pulsing {
  from {
    box-shadow: 0 0 0 0  var(--color-primary);
  }
  70% {
    box-shadow: 0 0 0 5px var(--color-secondary);
  }
  to {
    box-shadow: 0 0 0 0 var(--color-secondary);
  }
}
.markdown-chat .markdown-container p{
  color: #0d1117 !important;
}
.markdown-chat .markdown-container ol li{
  color: #0d1117 !important;
}
.markdown-chat-title .markdown-container p{
 margin: 0;
  margin-bottom: 10px;
}
.markdown-chat-title  p{
 margin: 0;
  margin-bottom: 5px;
  color: var(--color-primary)  !important;
}
.Mui-checked.checkAudio{
  color:var(--color-primary)  !important;
}
.Mui-checked.checkAudio  svg{
  color:var(--color-primary)  !important;
  fill: var(--color-primary)  !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-spinner {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid var(--color-primary) ; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-left: 8px; /* Add spacing between text and spinner */
}
.direction-rtl{
  text-align: right !important;
}
.direction-ltr{
  text-align: left !important;

}
.MuiBox-root button.detection-button {
  margin-top: 10px;
  color: #fff !important;
  background-color: var(--color-primary)  !important;
  padding: 13px 20px !important;
  border-radius: 8px;
  cursor: pointer;
  border: none !important;
  height: 45px !important;
  text-align: center;
}
.MuiBox-root button.detection-button:disabled{
  background-color: var(--color-secondary) !important;
  cursor: not-allowed;
}
.MuiBox-root button.detection-button span{
  color: #fff !important;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  color: #a8daec;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary) ;
}
.markdown-container table{

}
/*code refactor*/
/*login*/
.login-image{

}
.icon-style{
  color:var(--color-primary);
  margin-right:10px;
  font-weight:bold;
  font-size:19px
}

.font-19{
  font-size: 16px;
}
