.newsLettersPage .search-top{
    padding: 0px;
}

.newsLettersPage .color-white{
    color: #fff !important;
}

.line-height-0 {
    line-height: 0 !important;
}
