:root {
  --inter-font: 'Inter', sans-serif;
  --primary: var(--color-primary);
  --sub-main: #f7f8fa;
  --invert-color: #000;
  --border-color: #f0f2f5;
  --side-color: #f7f8fa;
  --icon-color:#666F8D;
}

