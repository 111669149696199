@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
// Icons font
@font-face {
  font-family: 'aip-icons';
  src:  url('../../fonts/aip-icons.eot?ac4gpu');
  src:  url('../../fonts/aip-icons.eot?ac4gpu#iefix') format('embedded-opentype'),
  url('../../fonts/aip-icons.ttf?ac4gpu') format('truetype'),
  url('../../fonts/aip-icons.woff?ac4gpu') format('woff'),
  url('../../fonts/aip-icons.svg?ac4gpu#aip-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aip-"], [class*=" aip-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aip-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aip-database:before {
  content: "\e92e";
  color: #3e90f0;
}
.aip-info-circle:before {
  content: "\e900";
}
.aip-upload-document:before {
  content: "\e901";
  //color: #666;
}
.aip-translate:before {
  content: "\e902";
}
.aip-support-chat:before {
  content: "\e903";
  color: #d84c10;
}
.aip-sun:before {
  content: "\e904";
}
.aip-send:before {
  content: "\e905";
}
.aip-search-status:before {
  content: "\e906";
  color: #666;
}
.aip-search-normal:before {
  content: "\e907";
}
.aip-file-attachment:before {
  content: "\e908";
  //color: #ee00f4;
}
.aip-rotate-right:before {
  content: "\e909";
}
.aip-rotate-left:before {
  content: "\e90a";
}
.aip-profile-user:before {
  content: "\e90b";
}
.aip-pdf:before {
  content: "\e90c";
  color: #f55157;
}
.aip-pause:before {
  content: "\e90d";
}
.aip-more:before {
  content: "\e90e";
}
.aip-moon:before {
  content: "\e90f";
}
.aip-brain-ai:before {
  content: "\e910";
  //color: #8e55ea;
}
.aip-microphone:before {
  content: "\e911";
}
.aip-message:before {
  content: "\e912";
}
.aip-majic:before {
  content: "\e913";
}
.aip-link:before {
  content: "\e914";
}
.aip-like:before {
  content: "\e915";
}
.aip-import:before {
  content: "\e916";
}
.aip-hugeicons-news:before {
  content: "\e917";
}
.aip-history:before {
  content: "\e918";
  color: #666;
}
.aip-grid-8:before {
  content: "\e919";
}
.aip-grid-2:before {
  content: "\e91a";
}
.aip-graph:before {
  content: "\e91b";
}
.aip-frame:before {
  content: "\e91c";
  //color: #a5a5a5;
}
.aip-folder-cloud:before {
  content: "\e91d";
  color: #666;
}
.aip-eye:before {
  content: "\e91e";
}
.aip-export:before {
  content: "\e921";
}
.aip-document-text:before {
  content: "\e923";
}
.aip-document-copy:before {
  content: "\e924";
}
.aip-dislike:before {
  content: "\e925";
}
.aip-close-square:before {
  content: "\e926";
}
.aip-broom:before {
  content: "\e927";
}
.aip-textext-alignment-left:before {
  content: "\e928";
  //color: #52ba69;
}
.aip-arrow-up:before {
  content: "\e929";
}
.aip-arrow-right:before {
  content: "\e92a";
}
.aip-arrow-left:before {
  content: "\e92b";
}
.aip-add:before {
  content: "\e92c";
}
.aip-arrow-down:before {
  content: "\e92d";
}
