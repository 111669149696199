@media screen and (max-width: 576px) {
  .newsletter__header {
    > div {
      width: 100%;
    }
  }
  .post {
    &__title {
      font-size: 1.2rem !important;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
