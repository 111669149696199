.btn {
  &__primary {
    box-shadow: 0px 2px 1px 0px #ffffff38 inset, 0px -2px 0.3px 0px #0e387d2e inset, 0px 2px 5px 0px #1458c92b !important;
    background: var(--primary) !important;
    border-radius: 8px !important;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    min-height: 38px !important;
    border: none !important;
    span {
      color: #fff !important;
      font-family: var(--inter-font);
    }
  }
  &__icon {
    color: var(--icon-color) !important;
  }
}

.icon-outline {
  border: 1px solid #d0d5dd !important;
  width: 44px;
  height: 49px;
  border-radius: 8px !important;
}
