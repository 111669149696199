.recording {
  max-width: 100% !important;
  width: 230px !important;
  // gap: 0.25rem;
  // height: 35px;
  // padding: 0.25rem;
  // > * {
  //   margin-left: 0 !important;
  //   padding: 0 !important;
  // }
  .audio-recorder-visualizer {
    width: 50px !important;
    overflow: hidden;
  }
}
