div.shortCut {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  padding: 6px;
  background-color: var(--sub-main);
  box-shadow: 0px 1px 3px 0px #19213d1a;
  color: #19213d !important;
  height: 28px;

  * {
    color: #19213d !important;
    font-family: var(--inter-font);
    font-weight: 500;
  }
}
