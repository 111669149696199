.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &__spinner {
    width: 50px;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--primary) !important;

    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;
  }

  /* HTML: <div class="loader"></div> */
  &__typing {
    width: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
  }
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
@keyframes l5 {
  0% {
    box-shadow: 20px 0 var(--primary), -20px 0 #e5eaee;
    background: var(--primary);
  }
  33% {
    box-shadow: 20px 0 var(--primary), -20px 0 #e5eaee;
    background: #e5eaee;
  }
  66% {
    box-shadow: 20px 0 #e5eaee, -20px 0 var(--primary);
    background: #e5eaee;
  }
  100% {
    box-shadow: 20px 0 #e5eaee, -20px 0 var(--primary);
    background: var(--primary);
  }
}
