.searchInput {
  border: 1px solid #f0f2f5;
  box-shadow: 0px 1px 3px 0px #19213d1a;
  background: var(--Sections-BG-Color-1, #ffffff);
  border-radius: 8px;
  padding: 6px 6px 6px 12px;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  &__icon {
    width: 14px;
    height: 18px;
  }
  input {
    all: unset;
    text-align: left;
    flex: 1;
    width: 100px;
    font-size: 12px;
    color: #666f8d;

    &::placeholder {
      font-size: 12px;
      color: #666f8d;
    }
  }
}
