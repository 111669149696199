@media screen and (max-width: 576px) {
  .chatPage {
    .chatInput {
      > div {
        > div {
          min-width: unset;
        }
      }
      &__control {
        gap: 1rem;
        flex-direction: column-reverse;
      }
      &__form {
        > div.MuiTextarea-root {
          padding: 1rem !important;
        }
      }
    }
    .chatPage .files-upload .chatCategory {
      width: 100%;
    }
    .search-top-content {
      position: relative;
      form {
        position: absolute;
      }
    }
  }
}
